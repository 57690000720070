define("discourse/plugins/discourse-follow/discourse/components/follow-post-stream", ["exports", "@ember/component", "discourse/components/user-stream", "@ember/template-factory"], function (_exports, _component, _userStream, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @stream.content as |post|}}
    {{follow-feed-post post=post}}
  {{/each}}
  */
  {
    "id": "oZckpMdU",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1,[\"content\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],null,[[\"post\"],[[30,2]]]]],[1,\"\\n\"]],[2]],null]],[\"@stream\",\"post\"],false,[\"each\",\"-track-array\",\"follow-feed-post\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/components/follow-post-stream.hbs",
    "isStrictMode": false
  });
  class FollowPostStream extends _userStream.default {}
  _exports.default = FollowPostStream;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FollowPostStream);
});