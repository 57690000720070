define("discourse/plugins/discourse-shared-edits/discourse/connectors/composer-fields-below/shared-edit-buttons", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SharedEditButtons extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "composer", [_service.service]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    endSharedEdit() {
      this.composer.close();
    }
    static #_3 = (() => dt7948.n(this.prototype, "endSharedEdit", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @outletArgs.model.creatingSharedEdit}}
          <div class="leave-shared-edit">
            <DButton
              @action={{this.endSharedEdit}}
              @icon={{if this.site.mobileView "xmark"}}
              @label={{if this.site.desktopView "shared_edits.done"}}
              title={{if this.site.mobileView (i18n "shared_edits.done")}}
              class={{if this.site.mobileView "btn-transparent" "btn-primary"}}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "+Sr8aWpR",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"model\",\"creatingSharedEdit\"]],[[[1,\"      \"],[10,0],[14,0,\"leave-shared-edit\"],[12],[1,\"\\n        \"],[8,[32,0],[[16,\"title\",[52,[30,0,[\"site\",\"mobileView\"]],[28,[32,1],[\"shared_edits.done\"],null]]],[16,0,[52,[30,0,[\"site\",\"mobileView\"]],\"btn-transparent\",\"btn-primary\"]]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"endSharedEdit\"]],[52,[30,0,[\"site\",\"mobileView\"]],\"xmark\"],[52,[30,0,[\"site\",\"desktopView\"]],\"shared_edits.done\"]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-shared-edits/discourse/connectors/composer-fields-below/shared-edit-buttons.js",
      "scope": () => [_dButton.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SharedEditButtons;
});